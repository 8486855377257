import React, { Component } from "react"
import { Link } from "gatsby"
import { RedButton } from "../components/button-red"
import { StaticImage } from "gatsby-plugin-image"
import Breadcrumb from "../components/breadcrumb"

export class GridSeven extends Component {
    render() {
        return (
            <div className="container mx-auto">
                <div className="hidden lg:block translate-x-[260px]">
                    <h2 className="px-5 font-bold text-2xl lg:text-3xl text-center lg:text-left lg:px-48 lg:translate-y-[10px] xl:px-[330px] 2xl:px-[360px]">{this.props.title}</h2>
                </div>
                <div className="lg:flex -mt-5">
                    <div className="px-8 w-3/4 mx-auto">
                        <nav className="flex container mx-auto py-3 shadow-4xl lg:translate-y-[-90px] lg:translate-x-[40px] lg:pt-10" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/services', label: 'Services' },
                            ]} />
                        </nav>

                        <div className="lg:hidden border rounded-xl p-5  mx-auto mb-10">
                            <span className="font-bold">All Services</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/single-stage-seat-belts'>
                                    Single Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/dual-stage-seat-belt'>
                                    Dual Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/triple-stage-seat-belt'>
                                    Triple Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/pretensioner'>
                                    Pretensioner Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-coloring-service/seat-belt-coloring'>
                                    Seat Belt Coloring
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/battery-cable-repair-service/battery-cable'>
                                    Battery Cable Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/steering-column-sensor-repair-service/collapsible-steering-sensor'>
                                    Steering Column Sensor Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/srs-airbag-module-repair-service/airbag-module-reset'>
                                    SRS Airbag Module Repair
                                </Link>
                            </div>
                            <div className="pt-3 pb-5">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/convertible-roll-bar-repair-service/convertible-roll-bar'>
                                    Convertible Roll Bar Repair
                                </Link>
                            </div>
                            {/* <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-headrest-repair-service/deployed-headrest'>
                                    Deployed Headrest Repair
                                </Link>
                            </div> */}
                            <span className="font-bold">All Products</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/airbag-plugs/airbag-plugs/'>
                                    Airbag Plugs
                                </Link>
                            </div>
                        </div>


                        <div className="hidden lg:block border rounded-xl p-5 w-3/4 2xl:w-1/2 translate-x-[60px] translate-y-[-90px]">
                            <span className="font-bold">All Services</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/single-stage-seat-belts'>
                                    Single Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/dual-stage-seat-belt'>
                                    Dual Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/triple-stage-seat-belt'>
                                    Triple Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/pretensioner'>
                                    Pretensioner Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-coloring-service/seat-belt-coloring'>
                                    Seat Belt Coloring
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/battery-cable-repair-service/battery-cable'>
                                    Battery Cable Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/steering-column-sensor-repair-service/collapsible-steering-sensor'>
                                    Steering Column Sensor Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/srs-airbag-module-repair-service/airbag-module-reset'>
                                    SRS Airbag Module Repair
                                </Link>
                            </div>
                            <div className="pt-3 pb-5">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/convertible-roll-bar-repair-service/convertible-roll-bar'>
                                    Convertible Roll Bar Repair
                                </Link>
                            </div>
                            {/* <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-headrest-repair-service/deployed-headrest'>
                                    Deployed Headrest Repair
                                </Link>
                            </div> */}
                            <span className="font-bold">All Products</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/airbag-plugs/airbag-plugs/'>
                                    Airbag Plugs
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p className="text-3xl font-bold pb-3 lg:hidden px-8">Automotive</p>
                    <div className="px-5 pt-5 pb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center gap-9 md:gap-5 text-white lg:mt-10 xl:translate-x-[-40px] 2xl:translate-x-[-200px]">

                        <div className="max-w-lg mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/seat-belt-repair-service/single-stage-seat-belts">
                                <StaticImage src="../images/single-stage-seat-belt.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Seat Belt Repair Service" alt="Seat Belt Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Single Stage Seat Belt</h3>
                                    <p className="text-sm pt-2">Equipped with 1 pressurized gas cartridge identified by the single connector or wire plug</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/seat-belt-repair-service/single-stage-seat-belts" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/seat-belt-repair-service/dual-stage-seat-belt">
                                <StaticImage src="../images/dual-stage-seat-belts.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Seat Belt Repair Service" alt="Seat Belt Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Dual Stage Seat Belt</h3>
                                    <p className="text-sm pt-2">Equipped with 2 pressurized gas cartridges identified by the dual connector or wire plug</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/seat-belt-repair-service/dual-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/seat-belt-repair-service/triple-stage-seat-belt">
                                <StaticImage src="../images/triple-stage-seat-belts.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Seat Belt Repair Service" alt="Seat Belt Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Triple Stage Seat Belt</h3>
                                    <p className="text-sm pt-2">Equipped with 3 pressurized gas cartridges identified by the triple connector or wire plug</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/seat-belt-repair-service/triple-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/seat-belt-repair-service/pretensioner">
                                <StaticImage src="../images/pretensioner.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Seat Belt Repair Service" alt="Seat Belt Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Pretensioner Repair</h3>
                                    <p className="text-sm pt-2">Safety strap or harness designed to hold a person securely in the seat. Also called a safety belt</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/seat-belt-repair-service/pretensioner" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/seat-belt-coloring-service/seat-belt-coloring">
                                <StaticImage src="../images/seat-belt-color-product.png" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Seat Belt Coloring Service" alt="Seat Belt Coloring Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Seat Belt Coloring</h3>
                                    <p className="text-sm pt-2">Change the color or restore your seat belt webbing</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/seat-belt-coloring-service/seat-belt-coloring" buttonText="Change My Webbing" title="Explore Seat Belt Coloring Service" alt="Explore Seat Belt Coloring Service" invert />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/battery-cable-repair-servic/battery-cable">
                                <StaticImage src="../images/battery-cable-product.png" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Battery Cable Repair Service" alt="Battery Cable Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Battery Cable Repair</h3>
                                    <p className="text-sm pt-2">Post accident Battery Cable repair to get your vehicle started</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/battery-cable-repair-service/battery-cable" buttonText="Repair My Fuse" title="Explore Battery Cable Repair Service" alt="Explore Battery Cable Repair Service" invert />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/steering-column-sensor-repair-service/collapsible-steering-sensor">
                                <StaticImage src="../images/steering-column-sensor.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Steering Column Sensor Repair Service" alt="Steering Column Sensor Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Steering Column Sensor Repair</h3>
                                    <p className="text-sm pt-2">Post accident steering column sensor repair using 100% OEM parts</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/steering-column-sensor-repair-service/collapsible-steering-sensor" buttonText="Repair My Sensor" title="Explore Steering Column Sensor Repair Service" alt="Explore Steering Column Sensor Repair Service" invert />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/srs-airbag-module-repair-service/airbag-module-reset">
                                <StaticImage src="../images/srs-module-reset.jpg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="SRS Airbag Module Repair Service" alt="SRS Airbag Module Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">SRS Airbag Module Repair</h3>
                                    <p className="text-sm pt-2">Reset your SRS airbag computer to finally turn that airbag light off</p>
                                </div>
                                <div className="pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/srs-airbag-module-repair-service/airbag-module-reset" buttonText="Repair My Module" title="Explore SRS Airbag Module Repair Service" alt="Explore SRS Airbag Module Repair Service" invert />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/convertible-roll-bar-repair-service/convertible-roll-bar">
                                <StaticImage src="../images/convertable-roll-bar-repair.jpeg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Convertible Roll Bar Repair Service" alt="Convertible Roll Bar Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Convertible Roll Bar Repair</h3>
                                    <p className="text-sm pt-2">Post accident convertible roll bar pop up repair using original parts for factory functioning</p>
                                </div>
                                <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/convertible-roll-bar-repair-service/convertible-roll-bar" buttonText="Repair My Rollbars" title="Explore Convertible Roll Bar Repair Service" alt="Explore Convertible Roll Bar Repair Service" invert />
                                </div>
                            </div>
                        </div>
                        {/* <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/deployed-headrest-repair-service/deployed-headrest">
                                <StaticImage src="../images/deployed-headrest-repair.jpeg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Deployed Headrest Repair Service" alt="Deployed Headrest Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-20">
                                    <h3 className="text-xl font-bold pt-5">Deployed Headrest Repair</h3>
                                    <p className="text-sm pt-2">Popped out headrest repair using 100% OEM factory parts to restore it to functioning condition</p>
                                </div>
                                <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/deployed-headrest-repair-service/deployed-headrest" buttonText="Repair My Headrest" title="Explore Deployed Headrest Repair Service" alt="Explore Deployed Headrest Repair Service" invert />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}