import * as React from "react"

function Car(props) {
    return (
        <svg className='-mt-1 xl:-mt-0.5' width="24" height="24" viewBox="0 0 24 24" fill="#9CA3AF" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.28905 4.64056C3.43772 4.25461 3.80863 4 4.22222 4H14.2222C14.5324 4 14.8251 4.14397 15.0144 4.38972L19.1586 9.76923H19.7778C20.6448 9.76923 21.4683 10.1273 22.0694 10.7515C22.6693 11.3745 23 12.2116 23 13.0769V17.6923C23 17.9575 22.8946 18.2119 22.7071 18.3994C22.5196 18.587 22.2652 18.6923 22 18.6923L20.6269 18.6923C20.2177 20.0135 19.0191 21 17.5556 21C16.092 21 14.8934 20.0135 14.4842 18.6923H9.51576C9.10663 20.0135 7.90802 21 6.44443 21C4.98085 21 3.78224 20.0135 3.37311 18.6923L2 18.6923C1.73478 18.6923 1.48043 18.587 1.29289 18.3994C1.10536 18.2119 1 17.9575 1 17.6923V10.7692C1 10.6463 1.02266 10.5245 1.06683 10.4098L3.28905 4.64056ZM3.37311 16.6923C3.78224 15.3711 4.98085 14.3846 6.44443 14.3846C7.90802 14.3846 9.10663 15.3711 9.51576 16.6923H14.4842C14.8934 15.3711 16.092 14.3846 17.5556 14.3846C19.0191 14.3846 20.2177 15.3711 20.6269 16.6923H21V13.0769C21 12.7182 20.8624 12.3814 20.6288 12.1388C20.3964 11.8975 20.0895 11.7692 19.7778 11.7692H3V16.6923H3.37311ZM3.4568 9.76923H11V6H4.90866L3.4568 9.76923ZM13 6V9.76923H16.634L13.7303 6H13ZM6.44443 16.3846C5.80465 16.3846 5.22221 16.9342 5.22221 17.6923C5.22221 18.4504 5.80465 19 6.44443 19C7.08421 19 7.66666 18.4504 7.66666 17.6923C7.66666 16.9342 7.08421 16.3846 6.44443 16.3846ZM17.5556 16.3846C16.9158 16.3846 16.3333 16.9342 16.3333 17.6923C16.3333 18.4504 16.9158 19 17.5556 19C18.1953 19 18.7778 18.4504 18.7778 17.6923C18.7778 16.9342 18.1953 16.3846 17.5556 16.3846Z" fill="#9CA3AF" />
        </svg>
    )
}
export default Car
