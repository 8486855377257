import * as React from "react"

function Border(props) {
    return (
        <svg className='translate-x-[230px] translate-y-[-35px] hidden md:block' width="1" height="40" viewBox="0 0 1 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.15" x1="0.5" y1="0.5" x2="0.499998" y2="39.5" stroke={props.color || 'white'} strokeLinecap="round" />
        </svg>

    )
}
export default Border
