import React, { useState } from "react"
// import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Car from "../icons/car"
import Calendar from "../icons/calendar"
import Chevron from "../icons/chevron"
import Border from "../icons/border"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SearchBar(props) {

    let disableButton = false
    let mainTitle = 'Select Your Vehicle Details To See All Repair Services!'

    const [vehicle, setVehicle] = useState(null)
    const [carYears, setCarYears] = useState(null)
    const windowGlobal = typeof window !== 'undefined' && window

    var [selectedMakeName, setSelectedMakeName] = React.useState("")
    var [selectedYear, setSelectedYear] = React.useState("")

    const vehicleSelected = (vehicleMake) => {
        setVehicle(vehicleMake)

        if (vehicleMake === "" || vehicleMake === null || vehicleMake === 'Select Vehicle') {
            setSelectedMakeName("")
            windowGlobal.localStorage.setItem("vehicleMake", "")
            windowGlobal.localStorage.setItem("vehicleYear", "")
            return
        }
        setSelectedMakeName(vehicleMake)
        windowGlobal.localStorage.setItem("vehicleMake", vehicleMake)
    }

    const yearSelected = (vehicleYear) => {
        setCarYears(vehicleYear)

        if (vehicleYear === "" || vehicleYear === null || vehicleYear === 'Year') {
            setSelectedYear("")
            windowGlobal.localStorage.setItem("vehicleMake", "")
            windowGlobal.localStorage.setItem("vehicleYear", "")
            return
        }
        setSelectedMakeName(vehicleYear)
        windowGlobal.localStorage.setItem("vehicleYear", vehicleYear)
    }

    let startYear = 2000
    let currentYear = new Date().getFullYear()
    const vehicleYears = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index)
    vehicleYears.unshift("Year")

    if ((!windowGlobal || !windowGlobal.localStorage || !windowGlobal.localStorage.getItem("vehicleMake")) || (!windowGlobal || !windowGlobal.localStorage || !windowGlobal.localStorage.getItem("vehicleYear"))) {
        disableButton = true
    }

    if ((windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) && (windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear"))) {
        mainTitle = 'All Repair Services For Your - ' + windowGlobal.localStorage.getItem("vehicleYear") + ' ' + windowGlobal.localStorage.getItem("vehicleMake")
    }

    const vehicleMakes = [
        { id: 0, make: "Select Vehicle" },
        { id: 1, make: "Acura" },
        { id: 2, make: "Alfa Romeo" },
        { id: 3, make: "Aston Martin" },
        { id: 4, make: "Audi" },
        { id: 5, make: "Bentley" },
        { id: 6, make: "BMW" },
        { id: 7, make: "Buick" },
        { id: 8, make: "Cadillac" },
        { id: 9, make: "Chevrolet" },
        { id: 10, make: "Chrysler" },
        { id: 11, make: "Dodge" },
        { id: 12, make: "Ferrari" },
        { id: 13, make: "Fiat" },
        { id: 14, make: "Ford" },
        { id: 15, make: "GMC" },
        { id: 16, make: "Honda" },
        { id: 17, make: "Hyundai" },
        { id: 18, make: "Infiniti" },
        { id: 19, make: "Jaguar" },
        { id: 20, make: "Jeep" },
        { id: 21, make: "Kia" },
        { id: 22, make: "Lamborghini" },
        { id: 23, make: "Land Rover" },
        { id: 24, make: "Lexus" },
        { id: 25, make: "Maserati" },
        { id: 26, make: "Mazda" },
        { id: 27, make: "Mclaren" },
        { id: 28, make: "Mercedes Benz" },
        { id: 29, make: "Mini Cooper" },
        { id: 30, make: "Mitsubishi" },
        { id: 31, make: "Nissan" },
        { id: 32, make: "Porsche" },
        { id: 33, make: "Rolls Royce" },
        { id: 34, make: "Scion" },
        { id: 35, make: "Subaru" },
        { id: 36, make: "Tesla" },
        { id: 37, make: "Toyota" },
        { id: 38, make: "Volkswagen" }
    ]

    return (


        <div className="relative">
            <div className="container mx-auto">
                <p className="px-5 font-bold text-3xl text-center">{mainTitle}</p>

                <div className="grid lg:grid-cols-3 w-64 md:w-[500px] mt-14 mx-auto relative lg:hidden border border-gray-600 rounded-lg text-xs xl:text-sm font-semibold bg-newlndgray">

                    <div className="lg:px-2 pt-1 xl:pt-0">
                        <Menu as="div" className="relative inline-block text-left lg:w-56 -mb-10">
                            <div>
                                <Menu.Button className="inline-flex justify-center gap-x-1.5 px-3 pt-4 text-xs xl:text-sm font-semibold">
                                    <Car />
                                    {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) || 'Select Vehicle'}
                                    <Chevron />
                                </Menu.Button>
                                <Border />
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >

                                <Menu.Items className="absolute left-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {vehicleMakes.map(vehicle => (
                                        <Menu.Item key={`${vehicle.make}`}>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                    onClick={() => {
                                                        vehicleSelected(vehicle.make)
                                                    }}
                                                >
                                                    {vehicle.make}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    <hr className="border-gray-500 mt-2 lg:hidden" />

                    <div className="lg:mx-auto pt-1 xl:pt-0">
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md px-3 pt-4 text-xs xl:text-sm font-semibold">
                                    <Calendar />
                                    {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear")) || 'Year'}
                                    <Chevron />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 mt-2 w-full origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {vehicleYears.map(years => (
                                        <Menu.Item key={`${years}`}>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                    onClick={() => {
                                                        yearSelected(years)
                                                    }}
                                                >
                                                    {years}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    <hr className="border-gray-500 mt-2 lg:hidden" />

                    <div className="flex justify-end px-3 py-3 lg:py-0">
                        {disableButton ? (
                            <button disabled className='relative h-9 mt-2 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out text-lndred'>
                                <span>Select Vehicle & Year</span>
                            </button>
                        ) : (
                            <Link to='/services' className='relative h-9 mt-2 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out bg-lndred text-white hover:shadow-lg hover:shadow-lndred text-sm'>
                                <span>Search Repair Services</span>
                            </Link>)}
                    </div>

                </div>

                <div className="hidden lg:block mt-10 border w-3/4 xl:w-[700px] mx-auto border-gray-600 rounded-lg text-xs xl:text-sm font-semibold bg-newlndgray h-14">
                    <div className="grid lg:grid-cols-3">
                        <div className="px-2 pt-1 xl:pt-0">
                            <Menu as="div" className="relative inline-block text-left w-56">
                                <div>
                                    <Menu.Button className="inline-flex justify-center gap-x-1.5 px-3 pt-4 text-xs xl:text-sm font-semibold">
                                        <Car />
                                        {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) || 'Select Vehicle'}
                                        <Chevron />
                                    </Menu.Button>
                                    <Border color='black' />
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >

                                    <Menu.Items className="absolute right-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {vehicleMakes.map(vehicle => (
                                            <Menu.Item key={`${vehicle.make}`}>
                                                {({ active }) => (
                                                    <button
                                                        className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                        onClick={() => {
                                                            vehicleSelected(vehicle.make)
                                                        }}
                                                    >
                                                        {vehicle.make}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>


                        <div className="mx-auto pt-1 xl:pt-0">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md px-3 pt-4 text-xs xl:text-sm font-semibold">
                                        <Calendar />
                                        {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear")) || 'Year'}
                                        <Chevron />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 mt-2 w-full origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {vehicleYears.map(years => (
                                            <Menu.Item key={`${years}`}>
                                                {({ active }) => (
                                                    <button
                                                        className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                        onClick={() => {
                                                            yearSelected(years)
                                                        }}
                                                    >
                                                        {years}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                        <div className="flex justify-end px-3">
                            {disableButton ? (
                                <button disabled className='relative h-9 mt-2 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out text-lndred'>
                                    <span>Select Vehicle & Year</span>
                                </button>
                            ) : (
                                <Link to='/services' className='h-9 mt-2 py-2 px-5 rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out bg-lndred text-white hover:shadow-lg hover:shadow-lndred text-sm'>
                                    <span>Search Repair Services</span>
                                </Link>)}
                        </div>

                    </div>
                </div>


            </div>


        </div>
    )
}
